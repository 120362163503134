<template>
  <section class="parameters">
    <h3>Parameters</h3>
    <ul v-show="parameters.length">
      <li>
        <label> Key </label>
        <label> Value </label>
      </li>
      <li v-for="(parameter, index) in parameters" :key="index">
        <span>{{ index?'&':'?' }}</span>
        <input type="text" name="key" v-model="parameter.key">
        <span>=</span>
        <input type="text" name="key" v-model="parameter.value" @change="updateEvent()">
        <button @click="deleteEvent(parameter)">&times;</button>
        <p v-if="parameter.description">{{parameter.description}}</p>
      </li>
    </ul>
    <div class="controls"><button @click="clearEvent()">clear all</button><button @click="addEvent()">+ add</button></div>
    <div class="controls"><button @click="addCRF()">Add CRF</button><button @click="addGA()">Add Google Analytics</button></div>
  </section>
</template>

<script>
import {bus} from "@/main";

export default {
  name: "Parameters",
  data() {
    return {
      parameters: []
    }
  },
  created () {
    bus.$on('addParameter', (data) => {
      this.parameters.push(data)
      bus.parameters = this.parameters
    })
  },
  methods: {
    updateEvent(){
      bus.parameters = this.parameters
    },
    deleteEvent(index) {
      this.parameters.splice(this.parameters.indexOf(index), 1)
      bus.parameters = this.parameters
    },
    addEvent(){
      this.parameters.push({key:null,value:null})
      bus.parameters = this.parameters
    },
    clearEvent() {
      this.parameters = []
      bus.parameters = this.parameters
    },
    addCRF() {
      this.parameters.push({key:'CRF',value:null})
      bus.parameters = this.parameters
    },
    addGA() {
      this.parameters.push({key:'utm_source',value:null, required:true, description: `The referrer (e.g. google, newsletter)`})
      this.parameters.push({key:'utm_medium',value:null, required:true, description: `Marketing medium (e.g. cpc, banner, email)`})
      this.parameters.push({key:'utm_campaign',value:null, required:true, description: `Product, promo code, or slogan (e.g. spring_sale) One of campaign name or campaign id are required.`})
      this.parameters.push({key:'utm_id',value:null, description: `The ads campaign id.`})
      this.parameters.push({key:'utm_term',value:null, description: `Identify the paid keywords`})
      this.parameters.push({key:'utm_content',value:null, description: `Use to differentiate ads`})
      bus.parameters = this.parameters
    }
  }
}
</script>

<style scoped>

section.parameters {
  width: 60%;
  min-width: 300px;
  margin: 2em auto;
}

ul {
  list-style: none;
}

ul li {
  display: flex;
  flex-wrap: wrap;
}

ul li label,
ul li input {
  width: 50%;
  flex: 1 0;
}

ul li span,
ul li button {
  margin: 20px;
}

ul li p {
  width: 100%;
  flex: 1 1 auto;
  margin-bottom: 1.5em;
  margin-top: 0;
}

.controls {
  margin-bottom: 15px;
}

.controls button {
  margin: 10px;
  padding: 10px;
}
</style>