<template>
  <div>
  <label for="base_url">URL
    <input type="url" id="base_url" @paste="pasteEvent" @change="ParseURL" v-model="baseURL"/>
  </label>
  </div>
</template>

<script>

import {bus} from "@/main";

export default {
  methods: {
    ParseURL: function() {
      if(this.baseURL) {
        const url = new URL(this.baseURL);

        for (const [key, value] of url.searchParams.entries()) {
          bus.$emit('addParameter', {key: key, value: value})
        }

        this.baseURL = this.baseURL.replace(url.search, '')
        bus.baseURL = this.baseURL
      }
    },
    pasteEvent(e) {
      setTimeout( ()=>e.target.blur(), 50 )
    }
  },
  data() {
    return {
      baseURL: null
    }
  }
}
</script>

<style scoped>

</style>