import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

export const bus = new Vue({data: {
        baseURL: '',
        parameters: [],
    }
});

new Vue({
  render: h => h(App),
    data: {
        baseURL: 'test',
        parameters: [],
    }
}).$mount('#app')
