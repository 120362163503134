<template>
  <section>
    <button @click="copyURL">Copy URL</button>
  </section>
</template>

<script>
import "toastify-js/src/toastify.css"
import {bus} from "@/main";
import ClipboardJS from "clipboard";
import Toastify from 'toastify-js'

export default {
  name: "Controls",
  methods: {
    copyURL() {
      try {
        var url = new URL(bus.baseURL);
      }catch(e){
        Toastify({
          text: e.message,
        }).showToast();
      }finally {

        bus.parameters.forEach(function (param) {
          if(param.value !== null && param.value !== ''){
            url.searchParams.append(param.key, param.value)
          }
        })

        const fakeEl = document.createElement('button')
        new ClipboardJS(fakeEl, {
          text: () => url.toString(),
        }).on('success', function (e) {
          e.clearSelection();
          Toastify({
            text: 'Copied!',
          }).showToast();
        }).on('failure', function () {
          Toastify({
            text: 'Couldn\'t copy. Press ctrl/cmd + c to copy!'
          }).showToast();
        })
        fakeEl.click()
      }

    },
    openURL() {
    }
  }
}
</script>

<style scoped>

</style>