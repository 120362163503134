<template>
  <div id="app">
    <h1>pURLs</h1>
    <p>Create URLs with query string parameters easier than ever.</p>
    <BaseURL />
    <Parameters />
    <Controls />
  </div>
</template>

<script>
import BaseURL from '@/components/BaseURL';
import Parameters from "@/components/Parameters";
import Controls from "@/components/Controls";

export default {
  name: 'App',
  components: {
    Parameters,
    BaseURL,
    Controls,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

input {
  width: 80%;
  height: 2em;
  padding: 10px;
  border-radius: 1em;
  margin: 8px;
}

button {
  padding: 10px;
  cursor: pointer;
}

</style>
